// import your page component
import SignInPage from '../views/devise/sessions/new.tsx'


// Mapping between your props template to Component, you must add to this
// to register any new page level component you create. If you are using the
// scaffold, it will auto append the identifers for you.
//
// e.g {'posts/new': PostNew}
export const pageIdentifierToPageComponent = {
    'devise/sessions/new': SignInPage,
};

