import React, {useEffect, useState} from 'react';
import emailSpellChecker from '@zootools/email-spell-checker';
import {MailSuggestion} from "@zootools/email-spell-checker/dist/lib/types";
import {AlertBar} from "@/components/alert-bar";
import {InputLayout} from "@/components/input-layout";
import {Button} from "@/components/button";
import Logo from "@assets/images/logo.svg";
import { useSelector } from 'react-redux';

interface SocialSignInButtonProps {
  csrfToken: string | null,
  callbackUrl: string | null
}

function GoogleButton({ csrfToken, callbackUrl }: SocialSignInButtonProps) {
  return (
    <form action="/api/auth/signin/google" method="POST">
      { csrfToken != null && <input type="hidden" name="csrfToken" value={csrfToken} /> }
      { callbackUrl != null && <input type="hidden" name="callbackUrl" value={callbackUrl} /> }
      <button type="submit" className="w-full text-black border border-gray-300 hover:bg-gray-300/25 focus:ring-4 focus:outline-none focus:ring-gray-300/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center dark:focus:ring-gray-300/55 mr-2 mb-2">
        <svg className="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path fill="#EA4335 " d="M5.26620003,9.76452941 C6.19878754,6.93863203 8.85444915,4.90909091 12,4.90909091 C13.6909091,4.90909091 15.2181818,5.50909091 16.4181818,6.49090909 L19.9090909,3 C17.7818182,1.14545455 15.0545455,0 12,0 C7.27006974,0 3.1977497,2.69829785 1.23999023,6.65002441 L5.26620003,9.76452941 Z"/>
          <path fill="#34A853" d="M16.0407269,18.0125889 C14.9509167,18.7163016 13.5660892,19.0909091 12,19.0909091 C8.86648613,19.0909091 6.21911939,17.076871 5.27698177,14.2678769 L1.23746264,17.3349879 C3.19279051,21.2936293 7.26500293,24 12,24 C14.9328362,24 17.7353462,22.9573905 19.834192,20.9995801 L16.0407269,18.0125889 Z"/>
          <path fill="#4A90E2" d="M19.834192,20.9995801 C22.0291676,18.9520994 23.4545455,15.903663 23.4545455,12 C23.4545455,11.2909091 23.3454545,10.5272727 23.1818182,9.81818182 L12,9.81818182 L12,14.4545455 L18.4363636,14.4545455 C18.1187732,16.013626 17.2662994,17.2212117 16.0407269,18.0125889 L19.834192,20.9995801 Z"/>
          <path fill="#FBBC05" d="M5.27698177,14.2678769 C5.03832634,13.556323 4.90909091,12.7937589 4.90909091,12 C4.90909091,11.2182781 5.03443647,10.4668121 5.26620003,9.76452941 L1.23999023,6.65002441 C0.43658717,8.26043162 0,10.0753848 0,12 C0,13.9195484 0.444780743,15.7301709 1.23746264,17.3349879 L5.27698177,14.2678769 Z"/>
        </svg>
        Bejelentkezés Google fiókkal
      </button>
    </form>
  )
}

function FacebookButton({ csrfToken, callbackUrl }: SocialSignInButtonProps) {
  return (
    <form action="/api/auth/signin/facebook" method="POST">
      { csrfToken != null && <input type="hidden" name="csrfToken" value={csrfToken} /> }
      { callbackUrl != null && <input type="hidden" name="callbackUrl" value={callbackUrl} /> }
      <button type="submit" className="w-full text-black border border-gray-300 hover:bg-gray-300/25 focus:ring-4 focus:outline-none focus:ring-gray-300/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center dark:focus:ring-gray-300/55 mr-2 mb-2">
        <svg className="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" clipRule="evenodd" fillRule="evenodd" imageRendering="optimizeQuality" shapeRendering="geometricPrecision" textRendering="geometricPrecision" viewBox="6702.77 18309.17 6561.66 6561.660000000007">
          <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="9983.6" x2="9983.6" y1="18249.39" y2="25150.62">
            <stop offset="0" stopColor="#00b2ff"/>
            <stop offset="1" stopColor="#006aff"/>
          </linearGradient>
          <path d="M9983.6 18309.17c1811.95 0 3280.83 1468.88 3280.83 3280.83s-1468.88 3280.83-3280.83 3280.83S6702.77 23401.95 6702.77 21590s1468.88-3280.83 3280.83-3280.83z" fill="url(#a)"/>
          <path d="M10409.89 24843.29v-2534.17h714.43l94.7-891.91h-809.13l1.2-446.44c0-232.63 22.1-357.22 356.24-357.22h446.68v-892.06h-714.59c-858.35 0-1160.42 432.65-1160.42 1160.34v535.45h-535.07v891.99H9339v2498.09c208.45 41.53 423.95 63.47 644.6 63.47a3310.9 3310.9 0 0 0 426.29-27.54z" fill="#fff" fillRule="nonzero"/>
        </svg>
        Bejelentkezés Facebook fiókkal
      </button>
    </form>
  )
}

function AppleButton({ csrfToken, callbackUrl }: SocialSignInButtonProps) {
  return (
    <form action="/api/auth/signin/apple" method="POST">
      { csrfToken != null && <input type="hidden" name="csrfToken" value={csrfToken} /> }
      { callbackUrl != null && <input type="hidden" name="callbackUrl" value={callbackUrl} /> }
      <button type="submit" className="w-full text-black border border-gray-300 hover:bg-gray-300/25 focus:ring-4 focus:outline-none focus:ring-gray-300/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center dark:focus:ring-gray-300/55 mr-2 mb-2">
        <svg className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path>
        </svg>
        Bejelentkezés Apple fiókkal
      </button>
    </form>
  )
}

interface Meta {
  isGoogleLoginEnabled: boolean,
  isFacebookLoginEnabled: boolean,
  isAppleLoginEnabled: boolean,
  areAnySocialLoginsEnabled: boolean
}

interface Params {
  paths: {
    session: string,
    newPassword: string
  },
  csrfToken: string | null,
  callbackUrl: string | null,
  error: string | null,
  meta: Meta
}

function FlashMessage() {
  const flash = useSelector((state: any) => state.flash);

  if (flash == null)
    return <></>;

  return <>
    { flash.notice && <AlertBar theme="information">
      { flash.notice }
    </AlertBar> }

    { flash.alert && <AlertBar theme="danger">
      { flash.alert }
    </AlertBar> }
  </>
}

function Form({ paths, csrfToken, callbackUrl, meta }: Params) {
  const [ email, setEmail ] = useState<string | undefined>(undefined);
  const [ emailSuggestion, setEmailSuggestion ] = useState<MailSuggestion | undefined>(undefined);

  useEffect(() => {
    if (email == null || email == '') {
      setEmailSuggestion(undefined);
      return;
    }

    const suggestion = emailSpellChecker.run({
      email: email,
      secondLevelDomains: []
    })

    setEmailSuggestion(suggestion);
  }, [email]);

  return (
    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
        Bejelentkezés
      </h1>

      <FlashMessage />

      { meta.areAnySocialLoginsEnabled && <>
        <div className="flex flex-col gap-1">
          { meta.isGoogleLoginEnabled && <GoogleButton csrfToken={csrfToken} callbackUrl={callbackUrl} /> }
          { meta.isFacebookLoginEnabled && <FacebookButton csrfToken={csrfToken} callbackUrl={callbackUrl} /> }
          { meta.isAppleLoginEnabled && <AppleButton csrfToken={csrfToken} callbackUrl={callbackUrl} /> }
        </div>
        <div className="flex flex-row">
          <div className="w-full h-0.5 self-center bg-gray-200 dark:bg-gray-800"></div>
          <div className="text-center mx-4 text-sm text-gray-500 dark:text-gray-400">vagy</div>
          <div className="w-full h-0.5 self-center bg-gray-200 dark:bg-gray-800"></div>
        </div>
      </> }
      <form className="space-y-4 md:space-y-6" action={paths.session} method="POST">
        { csrfToken != null && <input type="hidden" name="authenticity_token" value={csrfToken} /> }
        { callbackUrl != null && <input type="hidden" name="callbackUrl" value={callbackUrl} /> }

        <InputLayout name="email" label="E-mail cím">
          <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="peter@valami.hu" value={email} onChange={(e) => setEmail(e.target.value)} required={true} />
          { emailSuggestion && <div className="relative w-full">
            <div className="absolute mt-[3px] w-full ps-3 text-right text-xs text-gray-500 dark:text-gray-400">
              Erre gondolt:&nbsp;
              <span className="font-bold cursor-pointer hover:text-gray-600 hover:dark:text-gray-500" onClick={() => setEmail(emailSuggestion!.full)}>
                @
                <span className="underline">
                  { emailSuggestion?.domain }
                </span>
              </span>?
            </div>
          </div> }
        </InputLayout>

        <InputLayout name="password" label="Jelszó">
          <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required={true} />
        </InputLayout>

        <Button type="submit" className="w-full">
          Bejelentkezés
        </Button>

        <div className="space-y-2 md:space-y-4">
          <p className="text-center text-sm font-light text-gray-500 dark:text-gray-400">
            Elfelejtetted a jelszavad? <a href={paths.newPassword} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Új jelszó igénylése</a>
          </p>
          <p className="text-center text-sm font-light text-gray-500 dark:text-gray-400">
            Nincs még fiókod? <a href={'#'} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Regisztráció</a>
          </p>
        </div>
      </form>
    </div>
  )
}


export default function SignInPage({ paths, csrfToken, meta, error, callbackUrl }: Params) {
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto min-h-screen">
        <a href={'#'}
           className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="w-8 h-8 mr-2" src={Logo} alt="logo" width={32} height={32}/>
          {"menetrend.app"}
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <Form paths={paths} csrfToken={csrfToken} callbackUrl={callbackUrl} error={error} meta={meta}/>
        </div>
      </div>
    </section>
  )
}
