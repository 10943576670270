import React from "react"

export interface InputLayoutProps {
  name?: string | undefined,
  label: string,
  required?: boolean,
  error?: React.ReactNode | null | undefined,
  className?: string | undefined,
  children?: React.ReactNode
}

export function InputLayout({ name, label, required, error, className, children }: InputLayoutProps) {
    return (
      <div className={className}>
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}{ required && <sup title="Kötelező" className="inline-block relative -top-1 px-[2px] text-xs cursor-help">*</sup>}</label>
        {children}
        {error && <p className="mt-1 mb-2 text-sm font-medium text-red-500 dark:text-red-400">
          {error}
        </p>}
      </div>
    )
}
