import React from "react"
import { twMerge } from "tailwind-merge"

export type ButtonTheme = 'primary' | 'secondary' | 'tertiary';
export type ButtonAccent = 'default' | 'danger';

export interface ButtonProps {
  type?: 'submit' | 'button',
  enabled?: boolean,
  children?: React.ReactNode,
  className?: string,
  theme?: ButtonTheme,
  accent?: ButtonAccent,
  onClick?: () => void
}

export function Button(props: ButtonProps) {
  const {
    type = 'button',
    enabled = true,
    children,
    className,
    theme = 'primary',
    accent = 'default',
    onClick = undefined
  } = props;

  const themeClasses = {
    primary: [
      'text-white',
      accent == 'default' && 'bg-primary-600 dark:bg-primary-600 focus:ring-primary-300 dark:focus:ring-primary-800',
      accent == 'danger' && 'bg-red-600 dark:bg-red-600 focus:ring-red-300 dark:focus:ring-red-800',
      accent == 'default' && enabled && 'hover:bg-primary-700 dark:hover:bg-primary-700',
      accent == 'danger' && enabled && 'hover:bg-red-700 dark:hover:bg-red-700'
    ],
    secondary: [
      'border bg-gray-50',
      accent == 'default' && 'text-black',
      accent == 'danger' && 'text-red-500',
      enabled && 'hover:bg-gray-100'
    ],
    tertiary: [
      'border border-gray-300 bg-white',
      accent == 'default' && 'text-black',
      accent == 'danger' && 'text-red-500',
      enabled && 'hover:bg-gray-100'
    ],
  };

  const mergedClassName = twMerge(
    `px-5 py-2.5 rounded-lg text-center text-sm font-medium focus:ring-4 focus:outline-none`,
    themeClasses[theme],
    !enabled && `opacity-50 cursor-not-allowed`,
    className
  );

  return <button type={type} className={mergedClassName} onClick={onClick} disabled={!enabled}>{children}</button>
}
