import React from "react";
import { twMerge } from 'tailwind-merge';

interface AlertBarProps {
  children?: React.ReactNode;
  className?: string;
  theme: 'default' | 'information' | 'danger' | 'warning' | 'success';
}

export function AlertBar({ children, className, theme = 'default' }: AlertBarProps) {
  const themes = {
    default: 'bg-primary-50 text-gray-900 dark:bg-gray-800 dark:text-gray-400',
    information: 'bg-primary-50 text-gray-900 dark:bg-gray-800 dark:text-gray-400',
    danger: 'bg-red-50 text-red-800 dark:bg-gray-800 dark:text-red-400',
    warning: 'bg-orange-50 text-yellow-700 dark:bg-gray-800 dark:text-yellow-400',
    success: 'bg-green-50 text-green-600 dark:bg-gray-800 dark:text-gray-400'
  }

  return (
    <div className={twMerge('w-full flex items-baseline p-4 mb-4 text-sm rounded-lg', themes[theme], className)} role="alert">
      <svg className="flex-shrink-0 inline w-4 h-4 mr-3 relative top-[3px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
      <span className="sr-only">Info</span>
      <div>
        <span className="font-medium">
          {children}
        </span>
      </div>
    </div>
  )
}
